
.main{
    background-image: url("./MainGeet.png");
    background-repeat: no-repeat; 
    background-size: cover; 
    height: 100vh;
    max-width: 100%;
    width: auto;
}
  
  .aside {
    flex: 1; /* Растягиваем боковую панель, чтобы она занимала всю доступную ширину */
  }
  

.mais {
  text-align: center;
}

.hh {
  margin-bottom: 20px;
}

.carouselContainer {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
}

.carousel {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s ease-in-out;
}

.carouselItem {
  flex: 0 0 auto;
  width: 12.5%; /* 8 изображений в ряд (100% / 8) */
  text-align: center;
}

.carouselImage {
  width: 100%;
  height: auto;
}

.caption {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}

.lenk {
  text-decoration: none;
  color: black;
}

.lenk:hover {
  text-decoration: underline;
  color: blue;
}
