.carousel-container {
    position: relative;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: white;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
    padding: 10px;
    border-radius: 10px;
  }
  