:root {
  --color-bg: #5ad174;
  --color-bg-hover: #2614c5;
  --color-bg-body:  #161616;
  --bs-card-border-radius: 10px;
  --color-bg-my:#bbbfc3;
  --color-bg-body:#454348;
  --bs-card-bg:#ea2334;

}
.card{
  background-color:var(--bs-card-bg);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg-body);
}
.bg-mys {
  background-color:#444147;
}

header {
  background: linear-gradient(rgb(113, 113, 121), rgb(59, 56, 61));
  padding: 1rem 0;
  text-align: center; }
header > a {
  color: white;
  text-decoration: none;
  padding: 0 1rem;
}
.sec{
    overflow:hidden;
    width: 100%;
    border-radius: var(--bs-card-border-radius);
 }


h1 {
  text-align: center;
}

.container {
  max-width: 900px;
  padding: 2rem 0;
  margin: 0 auto;
}
.rowset{
  padding-left:0;
}
.rowset2{
  padding-right:0;
}
/* :root {
  --color-bg: #5ad174;
  --color-bg-hover: #2614c5;
  --color-bg-body: #161616;
  --bs-card-border-radius: 10px;
  --color-bg-my: #bbbfc3;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-size: cover;
  background-repeat: no-repeat; 
  background-position: center; 
  background-color: var(--color-bg-body);
} */

/* Далее следует ваш остальной CSS код */
/* ... */


